<template>
  <div class="repaymen-detail">
    <BaseHeaderBar
      title="Rincian"
      :showCustomerService="false"
      :showBackButton="true"
    />
    <div class="top-block">
      <p class="p2">Jumlah pinjaman sudah ditransfer ke rekening bank ini</p>
      <p class="p2">Nama Pengguna: {{ repaymentDetail.cudrstomerName }}</p>
      <p class="p1">
        {{ repaymentDetail.badrnkName }}（{{ repaymentDetail.badrnkAccount }}）
      </p>
    </div>
    <div class="detail-list">
      <!-- 借款金额 -->
      <div class="info">
        <p>Nominal Pinjaman</p>
        <p>Rp{{ formatNumberWithCommas(repaymentDetail.lodranAmount) }}</p>
      </div>
      <!-- 贷款日期 -->
      <div class="info">
        <p>Waktu Pinjaman</p>
        <p>{{ repaymentDetail.ardrrivalTime }}</p>
      </div>
      <!-- 还款日期 -->
      <div class="info">
        <p>Waktu Pembayaran</p>
        <p>{{ repaymentDetail.redrpaymentTime }}</p>
      </div>

      <!-- 贷款期限 -->
      <div class="info">
        <p>Tenor Pinjaman</p>
        <p>{{ repaymentDetail.tidrmers }}</p>
      </div>
      <!-- 逾期天数 -->
      <div class="info">
        <p>Jumlah Hari Keterlambatan</p>
        <p>{{ repaymentDetail.ovdrerdueDay }} Hari</p>
      </div>
      <!-- 逾期罚息 -->
      <div class="info">
        <p>Denda Keterlambatan</p>
        <p>
          Rp{{
            formatNumberWithCommas(repaymentDetail.ovdrerdueInterestpenalty)
          }}
        </p>
      </div>
      <!-- 逾期违约金 -->
      <div class="info last">
        <p>Biaya Penundaan Keterlambatan</p>
        <p>
          Rp{{
            formatNumberWithCommas(repaymentDetail.ovdrerdueLiquidateddamages)
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  name: 'RepaymenDetail',
  data () {
    return {
      repaymentDetail: {}
    }
  },
  created () {
    this.getRepaymentDetail()
  },
  methods: {
    getRepaymentDetail () {
      request.post('homeDRInterface')
        .then(res => {
          this.repaymentDetail = res.bidrllDetails
        })
    }
  }
}
</script>

<style scoped lang="less">
.repaymen-detail {
  min-height: 100vh;
  background-color: @body-bg-color;
}

.top-block {
  border-radius: 30px;
  margin: 29px 34px;
  padding: 30px 40px;
  background: linear-gradient(20deg, #d391f9 0%, #994df8 100%);
  p {
    color: #fff;

    &:nth-child(n + 2) {
      margin-top: 14px;
    }
  }

  .p1 {
    font-size: 30px;
    font-weight: 600;
  }

  .p2 {
    width: 530px;
    font-size: 26px;
  }
}

.detail-list {
  margin: 34px;
  background-color: #8875cf;
  padding-left: 30px;
  padding-right: 25px;
  color: #fff;
  border-radius: 30px;

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 86px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    font-size: 28px;
    font-weight: 400;
    p:nth-child(2) {
      font-weight: 600;
    }
  }
  .last {
    border: none;
  }
}
</style>
